export const periodeList  = [
    {
      label: '30 Days',
      duration:'30d',
    },
    {
      label: '60 Days',
      duration:'60d',
    },
    {
      label: '6 Month',
      duration:'180d',
    },
    {
      label: '12 Months',
      duration:'365d',
    },
  ];
  