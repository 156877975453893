import React, { useState } from 'react'
import StakePools from '../components/List/StakePools'
import {list } from '../data/StakingPoolLists';

function Home() {
    const [poolList, setPoolList] = useState(list);
    return (
        <>
        <div className="container col-xxl-8 px-4 py-5">
            <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
            
            <div className="col-lg-6">
                <div className='head-bar'/>
                <h1 className="display-5 lh-1 my-3 title-text fw-600">Arbitrage Opportunity High Perform Bot</h1>
                <p className="lead py-3">Crypto arbitrage with our bot buy, sell and triangulate the same asset or different assets simultaneously on selected exchanges to exploit the asset price difference and thus generate a continuous profit, we have brought together all the best features of the bots by perfecting them and adding unique functions.</p>
                <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                </div>
            </div>
            <div className="col-10 col-sm-8 col-lg-6">
                <img src="assets/images/misc/hero.png" className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy"/>
            </div>
            </div>
        </div>
        <div className='container'>
            <StakePools poolList={poolList} />
        </div>
        </>
    )
}

export default Home
