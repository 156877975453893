export const list  = [
    {
        title: 'Stake your $AOT',
        subtitle:'Reward $USDT.',
      icon:{
        stakeCoin:'assets/images/icon/icon_aot.png',
        rewardCoin:'assets/images/icon/icon_usdt.png'
      },
      apy:{
        label:'APY',
        value:'10%',
      },
      totalStake:{
        label:'Total Stake',
        value:'100 AOT'
      },
      totalStaker:{
        label:'Total Staker',
        value:'410'
      },
      contract:'0x21B45Bb6A2B87C608b97a9D521A7b34759718b51',
	  address:'0xF92751a29816b0A8bca885935aFcF52A1F25F92f',
      link: '/staking/0x21B45Bb6A2B87C608b97a9D521A7b34759718b51',
      status:'participate'
    },
  ];
  